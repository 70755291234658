<template>
	<main class="page-container" id="elearning">
		<div class="page-header-full">
			<div class="container">
				<h2 class="page-header-full_title">온라인 진출 배움터</h2>
				<p class="page-header-full_desc">
					디지털 전문가로 거듭나기를 희망하는 <br />
					소상공인 여러분 환영합니다.
				</p>
			</div>
		</div>
		<div class="page-body">
			<div class="container">
				<!-- 온라인 진출 배움터 이용방법 -->
				<!-- web화면 -->
				<div class="hidden-mobile">
					<section class="elearning-content">
						<div class="elearning-content-left">
							<div class="content-left-buttons">
								<div class="buttons">
									<router-link to="/educationpre" class="button-default is-large is-rounded is-primary"> 맛보기 교육 듣기 </router-link>
									<login-check-button class="button-default is-large is-rounded is-secondary" :click-func="goEducation" :is-auth="isAuth"> 정규과정 수강 바로가기 </login-check-button>
								</div>
							</div>
							<div class="content-left-icon-box">
								<img src="../../../assets/img/elearning/infoform_icon.png" alt="온라인 진출 배움터 아이콘" />
							</div>
						</div>
						<div class="elearning-content-right">
							<h3 class="content-right-title">온라인 진출 배움터 이용방법</h3>
							<ol class="content-right-elearning-guide">
								<li class="elearning-guide-item">
									<div class="guide-item-title">
										<span class="item-title-number">STEP 1</span>
										<span class="item-title">판판대로에서 소상공인 인플루언서 교육 신청하기</span>
									</div>
									<div class="guide-item-content">
										<div class="buttons">
											<!-- <a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403060100&sprtBizTrgtYn=N" target="_blank" class="button-default is-large is-rounded is-light">라이브커머스 교육 신청</a>
											<a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403060200&sprtBizTrgtYn=N" target="_blank" class="button-default is-large is-rounded is-light">미디어커머스 교육 신청</a> -->
											<router-link to="/supportbiz/intrd/5" class="button-default is-large is-rounded is-light">라이브커머스 교육 신청</router-link>
											<router-link to="/supportbiz/intrd/5" class="button-default is-large is-rounded is-light">미디어커머스 교육 신청</router-link>
										</div>
									</div>
								</li>
								<li class="elearning-guide-item">
									<div class="guide-item-title">
										<span class="item-title-number">STEP 2</span>
										<span class="item-title">교육신청 및 선정 후, 좌측 '정규과정 수강하기'를 통해 교육플랫폼 입장 및 교육 개시</span>
									</div>
								</li>
								<li class="elearning-guide-item">
									<div class="guide-item-title">
										<span class="item-title">&#8251; 온라인 시장 진출교육 수강하기</span>
									</div>
									<ul class="guide-item-process">
										<li>
											<span class="guide-item-process-step">플랫폼 회원가입</span>
										</li>
										<li>
											<span class="guide-item-process-step">온라인 시장 진출교육<br />수강 동의</span>
										</li>
										<li>
											<span class="guide-item-process-step">플랫폼에서 수강 가능<br />5일 이내 교육</span>
										</li>
									</ul>
								</li>
							</ol>
						</div>
					</section>
				</div>
				<!-- end-web -->
				<!-- 모바일화면 -->
				<div class="visible-mobile">
					<section class="elearning-content">
						<div class="elearning-content-left">
							<div class="content-left-desc">
								<h3 class="content-left-desc-title">온라인 진출 배움터란?</h3>
								<p class="content-left-desc-text">온라인 역량강화를 희망하는 소상공인을 대상으로 e-러닝 교육 및 실습 교육을 지원하는 사업입니다.</p>
							</div>
							<div class="content-left-icon-box">
								<img src="../../../assets/img/elearning/infoform_icon.png" alt="온라인 진출 배움터 아이콘" />
							</div>
						</div>
						<div class="elearning-content-right">
							<h3 class="content-right-title">이용방법</h3>
							<ol class="content-right-elearning-guide">
								<li class="elearning-guide-item">
									<div class="guide-item-title">
										<span class="item-title-number">STEP 1</span>
										<span class="item-title">플랫폼에서 온라인 시장 진출 교육 수강</span>
									</div>
									<ul class="guide-item-process">
										<li class="guide-item-process-li">
											<img src="../../../assets/img/elearning/process_icon01.png" alt="플랫폼 이용방법" />
											<span class="item-content-process-li-title">플랫폼 회원가입</span>
										</li>
										<li class="guide-item-process-li">
											<img src="../../../assets/img/elearning/process_icon02.png" alt="플랫폼 이용방법" />
											<span class="item-content-process-li-title">온라인 시장 진출교육 수강 동의</span>
										</li>
										<li class="guide-item-process-li">
											<img src="../../../assets/img/elearning/process_icon03.png" alt="플랫폼 이용방법" />
											<span class="item-content-process-li-title">5일 이내 교육 플랫폼에서 수강 가능</span>
										</li>
									</ul>
								</li>
								<li class="elearning-guide-item">
									<div class="guide-item-title">
										<span class="item-title-number">STEP 2</span>
										<span class="item-title">판판대로에서 소상공인 인플루언서 교육 신청하기</span>
									</div>
									<div class="guide-item-content">
										<div class="buttons">
											<a href="https://fanfandaero.kr/portal/preSprtBizPbanc.do" target="_blank" class="button-default is-large is-rounded is-light">라이브커머스 교육 신청</a>
											<a href="https://fanfandaero.kr/portal/preSprtBizPbanc.do" target="_blank" class="button-default is-large is-rounded is-light">미디어커머스 교육 신청</a>
										</div>
									</div>
								</li>
								<li class="elearning-guide-item">
									<div class="guide-item-title">
										<span class="item-title-number">STEP 3</span>
										<span class="item-title">교육신청 및 선정 후, 좌측 '정규과정 수강하기'를 통해 교육플랫폼 입장 및 교육 개시</span>
									</div>
									<div class="guide-item-content">
										<div class="buttons">
											<router-link to="/educationpre" class="button-default is-large is-rounded is-primary"> 맛보기 교육 듣기 </router-link>
											<login-check-button class="button-default is-large is-rounded is-secondary" :click-func="goEducation" :is-auth="isAuth"> 정규과정 수강 바로가기 </login-check-button>
										</div>
									</div>
								</li>
							</ol>
						</div>
					</section>
				</div>
				<!-- end-mobile -->
				<section class="elearning-content-process-introduct">
					<h3 class="elearning-content-title">교육과정 소개</h3>
					<ul class="elearning-content-process-list">
						<li>
							<article class="process-list-item">
								<div class="process-list-item-imgbox">
									<div class="imgbox-icons">
										<span class="imgbox-icon">e러닝</span>
									</div>
									<img src="../../../assets/img/elearning/content_img01.jpg" alt="온라인 시장 진출교육" />
								</div>
								<div class="process-list-item-textbox">
									<h4 class="textbox-title">온라인 시장 진출교육</h4>
									<p class="textbox-desc">소상공인의 온라인 시장 진출 역량 제고와 활성화를 위해 온라인교육(e러닝)제공</p>
									<div class="textbox-buttons">
										<div class="buttons">
											<a href="https://edu.sbiz.or.kr/edu/main/main.do" target="_blank" class="button-default is-rounded is-large is-primary">자세히 보기</a>
											<!-- <span class="button-default is-rounded is-large is-secondary">신청하기</span> -->
										</div>
									</div>
								</div>
							</article>
						</li>
						<li>
							<article class="process-list-item">
								<div class="process-list-item-imgbox">
									<div class="imgbox-icons">
										<span class="imgbox-icon">e러닝</span>
										<span class="imgbox-icon">실습</span>
									</div>
									<img src="../../../assets/img/elearning/content_img02.jpg" alt="라이브커머스 교육" />
								</div>
								<div class="process-list-item-textbox">
									<h4 class="textbox-title">라이브커머스 교육</h4>
									<p class="textbox-desc">실시간 생방송으로 상품을 판매하는 라이브커머스 역량을 강화하기 위한 이러닝 교육부터 실전 송출 실습까지 체계적 교육 지원</p>
									<div class="textbox-buttons">
										<div class="buttons">
											<a href="https://portal.valuebuy.kr/supportbiz/intrd" target="_blank" class="button-default is-rounded is-large is-primary">자세히 보기</a>
											<!-- <span class="button-default is-rounded is-large is-secondary">신청하기</span> -->
										</div>
									</div>
								</div>
							</article>
						</li>
						<li>
							<article class="process-list-item">
								<div class="process-list-item-imgbox">
									<div class="imgbox-icons">
										<span class="imgbox-icon">e러닝</span>
										<span class="imgbox-icon">실습</span>
									</div>
									<img src="../../../assets/img/elearning/content_img03.jpg" alt="미디어커머스 교육" />
								</div>
								<div class="process-list-item-textbox">
									<h4 class="textbox-title">미디어커머스 교육</h4>
									<p class="textbox-desc">동영상 촬영, 편집, 모바일 장비 활용법, 촬영 실습 등 소상공인의 콘텐츠 제작 능력 향상을 위한 이론 기초부터 실습까지 교육 지원</p>
									<div class="textbox-buttons">
										<div class="buttons">
											<a href="https://portal.valuebuy.kr/supportbiz/intrd" target="_blank" class="button-default is-rounded is-large is-primary">자세히 보기</a>
											<!-- <span class="button-default is-rounded is-large is-secondary">신청하기</span> -->
										</div>
									</div>
								</div>
							</article>
						</li>
					</ul>
				</section>
			</div>
		</div>
	</main>
	<!-- end::page-container(#온라인 진출 배움터) -->
</template>

<script>
import { ACT_GET_EDU_COURSE_LIST, ACT_POST_EDU_SSO } from '../../../store/_act_consts';
import { getItems, getPaging, isSuccess, lengthCheck, setPaging } from '../../../assets/js/utils';
import { mapGetters } from 'vuex';
import store from '../../../store';
import { MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import router from '../../../router';
import LoginCheckButton from '../../../components/common/LoginCheckButton';

export default {
	name: 'Education',
	components: {
		LoginCheckButton,
	},
	data: () => ({
		isLoading: false,
		hasMore: false,
		totalCount: 0,
		pageSize: 12,
		pageNo: 1,
		items: [],
		courseTypeDcds: [
			{ courseTypeDcd: '1012001', courseType: '이러닝' },
			{ courseTypeDcd: '1012002', courseType: '전화어학' },
			{ courseTypeDcd: '1012003', courseType: '집합교육' },
			{ courseTypeDcd: '1012004', courseType: '북러닝' },
			{ courseTypeDcd: '1012005', courseType: '소셜' },
			{ courseTypeDcd: '1012006', courseType: '마이크로러닝' },
			{ courseTypeDcd: '1012007', courseType: '블렌디드' },
		],
	}),
	computed: {
		...mapGetters('auth', ['isAuth']),
		...mapGetters('common', ['isMobile']),
		refineItems() {
			return this.items.map((x) => ({
				courseId: x.courseId,
				courseNm: x.courseNm,
				courseTypeDcd: x.courseTypeDcd,
				courseType: ((item) => {
					const courseFilter = this.courseTypeDcds.filter((f) => item.courseTypeDcd === f.courseTypeDcd);

					if (courseFilter.length > 0) return courseFilter[0].courseType;
					return '';
				})(x),
				categoryNm3: x.categoryNm3,
				courseImg: x.courseImg,
				previewYn: x.previewYn,
			}));
		},
	},
	created() {
		this.getCourse(true);
	},
	methods: {
		getCourse(isInit) {
			if (isInit) {
				this.items = [];
				this.isLoading = true;
			}

			this.$store
				.dispatch(`education/${ACT_GET_EDU_COURSE_LIST}`, {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = this.items.concat(getItems(res));
						setPaging(this, res);
					} else {
						this.hasMore = false;
					}
				})
				.catch((e) => {
					this.hasMore = false;
					console.error(e);
				});
		},
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getCourse();
		},
		clickPreview(courseId) {
			window.open(`https://sbdc.getsmart.co.kr/course/${courseId}/player/preview`, '새창', 'width=800, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=yes, encrypted-media');
		},
		goEducation() {
			this.$store.dispatch(`education/${ACT_POST_EDU_SSO}`).then((res) => {
				if (isSuccess(res)) {
					if (res.encId) {
						location.href = `https://sbdc.getsmart.co.kr/client/sso?memberId=${encodeURIComponent(res.encId)}`;
					}
				}
			});
		},
	},
};
</script>
